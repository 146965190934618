import React, { useState, useEffect } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import './ResetPassword.css'; // Import the CSS file
import zxcvbn from 'zxcvbn';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate,useLocation } from "react-router-dom";


export const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({ score: 0, feedback: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  
  const [guid, setGuid] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const encodedGuid = encodeURIComponent(guid);
    // Extract guid from URL query parameter
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const guidParam = params.get('guid');
      if (guidParam) {
        setGuid(guidParam);      
      } else {
        toast.error('Invalid reset password link');
      }
    }, [location]);

  useEffect(() => {
    if (newPassword) {
      const result = zxcvbn(newPassword);
      setPasswordStrength({
        score: result.score,   
      });
    } else {
      setPasswordStrength({ score: 0 });
    }
  }, [newPassword]);

  const getStrengthClass = (score) => {
    switch (score) {
      case 0:
        return 'strength-worst';
      case 1:
        return 'strength-bad';
      case 2:
        return 'strength-weak';
      case 3:
        return 'strength-good';
      case 4:
        return 'strength-strong';
      default:
        return '';
    }
  };

  //function to handle reset password
  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    } else if (passwordStrength.score < 2) {
      toast.error('Password is too weak!');
      return;
    }  
  // Ensure guid is available
  if (!guid) {
    toast.error('Invalid reset password link');
    return;
  }
  
    // Call the backend API to reset password
    try {
      const response = await fetch(`https://auth.decentra.net/identity/setpassword?guid=${encodeURIComponent(guid)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPassword),   
      
        
      });
      console.log("Password reset request:", guid, newPassword);
      console.log("Response status:", response.status);
    
  
      if (response.ok) {
        toast.success('Password reset successfully!');
        navigate("/signin"); //navigate to loginpage

      } else {
        toast.error('Error resetting password. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while resetting your password.');
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-form">
        <h2>Reset Password</h2>  <hr></hr>     
        <div className="form-group mt-4">
        <div className="position-relative">
          <MDBInput
            label="New Password"
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            wrapperClass="mt-3"
          />          
        </div>
        
        {/* Password strength meter */}
          <div
            className={`password-strength-meter-container`}
            style={{ height: '10px' }}  // Reserve space for the strength feedback
          >
            {newPassword && (
              <div className={`password-strength-meter ${getStrengthClass(passwordStrength.score)}`}>
                <p style={{ fontSize: '12px', margin: 0 }}>
                Strength: {['Worst', 'Bad', 'Weak', 'Good', 'Strong'][passwordStrength.score]}
                </p>
                <div className="password-strength-feedback">
                  {passwordStrength.feedback}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form-group mt-3">
        <div className="position-relative">
          <MDBInput
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            wrapperClass="mt-3"
          />          
          </div>

         {/* Reserve space for the error message */}
          <div style={{ height: '20px', marginBottom: '10px' }}> 
            {newPassword && confirmPassword && (
              <p style={{ color: 'red', fontSize: '12px', margin: 0 }}>
                {newPassword !== confirmPassword ? 'Passwords do not match!' : ''}
              </p>
            )}
          </div>
        </div>

        {/* Reset Password Button */}
        <Button
          className="btn bg-myColor"
          onClick={handleResetPassword}
          style={{ cursor: 'pointer' }}          
          disabled={newPassword !== confirmPassword || passwordStrength.score < 2}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default ResetPassword;
